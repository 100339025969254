import { createRouter, createWebHistory } from 'vue-router'
import Main from '../views/Main.vue'

const routes = [
  {
    path: '/',
    component: () => import('../views/Login.vue'), 
    name:'login'
  },
  {
    path: '/body',
    component: Main,
    children: [
      { path: '/dashboard', component: () => import('../views/Dashboard.vue'), name: 'dashboard', props: true},
      { path: '/devp', component: () => import('../views/Maintainance.vue') },
      { path: '/stock', component: () => import('../views/StockBalance.vue') },
      { path: '/stockInReport', component: () => import('../views/StockInReport.vue') },
      { path: '/products', component: () => import('../views/Products.vue')},
      { path: '/productsForm/:id', component: () => import('../components/ProductForm.vue'), name:'productForm'  },
      { path: '/sales', component: () => import('../views/Sales.vue') },
      { path: '/salesReport', component: () => import('../views/SalesReport.vue') },
      { path: '/saleForm', component: () => import('../components/SaleForm.vue') },
      { path: '/stores', component: () => import('../views/Stores.vue') },
      { path: '/storeForm/:id', component: () => import('../components/StoreForm.vue'), name:'storeForm'  },
      { path: '/storeProducts/:id', component: () => import('../views/StoreProducts.vue'), name:'storeProducts'},
      { path: '/addProductToStore/:id', component: () => import('../components/AddStoreProduct.vue'), name:'addProductToStore'},
      { path: '/categories', component: () => import('../views/Categories.vue') },
      { path: '/categoryForm/:id', component: () => import('../components/CategoryForm.vue'), name: 'categoryForm' },
      { path: '/expenses', component: () => import('../views/Expenses.vue') },
      { path: '/expenseForm/:id', component: () => import('../components/ExpensesForm.vue'), name:'expenseForm'  },

    ],
  },
  {
    path: '/register',
    component: () => import('../views/Register.vue'),
    name: 'register'
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
