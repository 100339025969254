<template>
    <footer class="z-10 flex px-4 py-8 bg-gray-200 shadow-md justify-content-center align-items-center dark:bg-slate-900 dark:text-white">
        <div class="text-sm text-gray-600 dark:text-gray-300">Copyright © 2023 My Company</div>
        <div class="flex gap-4 ml-auto">
            <a href="#" class="text-gray-700 hover:text-gray-900 dark:text-gray-500 hover:dark:text-gray-700">Terms &
                Conditions
            </a>
            <a href="#" class="text-gray-700 hover:text-gray-900 dark:text-gray-500 hover:dark:text-gray-700">Privacy
                Policy
            </a>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>