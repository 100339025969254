<template>
    <Header />
    <main class="">
    <div class="mt-14" >
      
      <Nav></Nav>
      
    </div>
    <div class="px-8 py-8 pt-6 bg-gray-100 sm:ml-60">
          <router-view />
          <!-- <Footer /> -->
    </div>
    
    </main>
    
  </template>
  
  <script>
  import Header from '../components/Header.vue';
  import Nav from '../components/Nav.vue';
  import Footer from '@/components/Footer.vue';
//   import StudentNav from '../components/StudentNav.vue';
//   import LectureNav from '../components/LectureNav.vue';
//   import ReceptionNav from '@/components/ReceptionNav.vue';
// import HTONav from '@/components/HTONav.vue';


  
  export default {
    components: { Nav, Header,Footer },
    data(){
      return {
        role: this.$store.state.userRoles 
      }
    }
    
    
  }
  </script>
  
  <style>
  
  </style>